import ApiBodyPartGroupLocale from 'types/api/BodyPartGroupLocale';

import Locale from 'models/Locale';
import Model from 'models/Model';

export default class BodyPartGroupLocale implements Model {
    id: string;
    name: string;
    enabled: boolean;
    slug: string;
    locale: Locale;

    constructor(data: ApiBodyPartGroupLocale) {
        this.id = data.id;
        this.name = data.name;
        this.enabled = data.enabled;
        this.slug = data.slug;
        this.locale = data.locale && new Locale(data.locale) || null;
    }
}
