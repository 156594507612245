import ApiProductPricingMatrix from 'types/api/ProductPricingMatrix';

import Currency from 'models/Currency';
import Model from 'models/Model';

import { changeFromCent, displayPrice } from 'utils/math';

export default class ProductPricingMatrix implements Model {
    id: string;
    currency: Currency;
    price: number;
    priceDisplay: string;
    quantityFrom: number;
    quantityTo: number;

    constructor(data: ApiProductPricingMatrix) {
        this.id = data.id;
        this.currency = data.currency && new Currency(data.currency);
        this.price = changeFromCent(data.price);
        this.priceDisplay = displayPrice(this.price, this.currency);
        this.quantityFrom = data.quantityFrom;
        this.quantityTo = data.quantityTo;
    }
}


