import moment, { Moment } from 'moment-timezone';

import ApiCartItem from 'types/api/CartItem';

import Bundle from 'models/Bundle';
import CartItemData from 'models/CartItemData';
import Currency from 'models/Currency';
import Location from 'models/Location';
import { Timezone } from 'models/Market';
import Model from 'models/Model';
import Product, { ProductTypes } from 'models/Product';
import ProductPricingMatrix from 'models/ProductPricingMatrix';
import Voucher from 'models/Voucher';

import { getFormattedDate } from 'utils/date';
import { changeFromCent, displayPrice } from 'utils/math';

//TODO: move to types
export enum Types {
    Offer = 'offer',
    Product = 'product',
    Voucher = 'voucher',
    Package = 'package',
    Bundle = 'bundle',
}

export interface CartProducts {
    discountCodeValue: number;
    discountValue: number;
    originalPrice: number;
    price: number;
    product: Product;
    quantity: number;
}

export interface CartBundles {
    discountCodeValue: number;
    discountValue: number;
    originalPrice: number;
    price: number;
    bundle: Bundle;
    quantity: number;
}

export interface CartProductsSoft {
    discountCodeValue: number;
    displayDiscountCodeValue: string;
    finalPrice: number;
    finalPriceDisplay: string
    marketingPrice: number;
    marketingPriceDisplay: string;
}

export interface Pricing {
    hasPromo: boolean;

    marketingPrice: number;
    marketingPriceDisplay: string;

    originalPrice: number;
    originalPriceDisplay: string;

    promoPrice: number;
    promoPriceDisplay: string;
    promoPercent: number;
    promoPercentDisplay: string;

    finalPrice: number;
    finalPriceDisplay: string;

    hasSavings: boolean;
    savingsPercent: number;
    savingsPercentDisplay: string;
}

export default class CartItem implements Model {
    origin: ApiCartItem;
    id: string;
    createdAt: Moment;
    createdAtDisplay: string;
    itemType: Types;
    quantity: number;
    item: any;
    pricing: Pricing;
    data: CartItemData;
    location?: Location;
    isAccepted: boolean;
    discount?: CartProductsSoft;

    constructor(data: ApiCartItem, currency?: Currency, cartProducts?: CartProducts[], cartBundles?: CartBundles[]) {
        this.origin = data;
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).tz(Timezone);
        this.createdAtDisplay = this.createdAt && getFormattedDate(this.createdAt);
        this.itemType = data.itemType;
        this.quantity = data.quantity;
        this.data = data.data && new CartItemData(data.data);
        this.item = data.item && this.getItem(this.itemType, data.item);
        this.pricing = this.getPricing(currency);
        this.discount = (cartProducts && data.itemType !== Types.Voucher) && data.itemType === Types.Bundle
            ? this.getDiscountPricing(currency, null, cartBundles)
            : this.getDiscountPricing(currency, cartProducts);
        this.location = data.location && new Location(data.location);
        this.isAccepted = typeof data.isAccepted === 'boolean'
            ? data.isAccepted
            : true;
    }

    getItem = (itemType: string, item: any): Voucher | Product | Bundle => {
        switch (itemType) {
            case Types.Product:
                return new Product(item) || null;
            case Types.Voucher:
                return new Voucher(item) || null;
            case Types.Bundle:
                return new Bundle(item) || null;
        }
    };

    getDiscountPricing = (currency: Currency, products: CartProducts[], bundles?: CartBundles[]): CartProductsSoft | null => {
        let productOrBundle: CartProducts | CartBundles;

        if (bundles) {
            productOrBundle = bundles?.find(cartProduct => cartProduct?.bundle?.id === this.item.id);
        } else {
            productOrBundle = products?.find(cartProduct => cartProduct.product.id === this.item.id);
        }

        if (!productOrBundle?.discountCodeValue) return;

        const discountCodeValue: number = changeFromCent(productOrBundle.discountCodeValue);
        const displayDiscountCodeValue: string = displayPrice(discountCodeValue, currency);
        const finalPrice: number = changeFromCent(productOrBundle.price);
        const finalPriceDisplay: string = displayPrice(finalPrice, currency);
        const marketingPrice: number = changeFromCent(productOrBundle.originalPrice);
        const marketingPriceDisplay: string = displayPrice(marketingPrice, currency);

        return {
            discountCodeValue,
            displayDiscountCodeValue,
            finalPrice,
            finalPriceDisplay,
            marketingPrice,
            marketingPriceDisplay,
        };
    };

    getPricing = (currency: Currency): Pricing => {
        const pricing: Pricing = {
            hasPromo: false,
            marketingPrice: 0,
            marketingPriceDisplay: null,
            originalPrice: 0,
            originalPriceDisplay: null,
            promoPrice: 0,
            promoPriceDisplay: null,
            promoPercent: 0,
            promoPercentDisplay: null,
            finalPrice: 0,
            finalPriceDisplay: null,
            hasSavings: false,
            savingsPercent: 0,
            savingsPercentDisplay: null,
        };

        let product: Product = null;
        let voucher: Voucher = null;
        let bundle: Bundle = null;

        let productPricingMatrix: ProductPricingMatrix = null;
        let productMarketingPricingMatrix: ProductPricingMatrix = null;
        let productPromoPricingMatrix: ProductPricingMatrix = null;
        let productMarketingPromoPricingMatrix: ProductPricingMatrix = null;
        let price: number = 0;
        let priceMarketing: number = 0;
        let pricePromo: number = 0;

        switch (this.itemType) {
            case Types.Product:
                product = this.item;

                if (product.productType === ProductTypes.Product) {
                    productPricingMatrix = product.getMatrixPrice(product.pricingMatrixes, this.quantity);
                    productMarketingPricingMatrix = product.getMatrixPrice(product.pricingMatrixes, 1);

                    if (!productPricingMatrix || !productMarketingPricingMatrix) {
                        break;
                    }

                    price = productPricingMatrix?.price * this.quantity;
                    priceMarketing = productMarketingPricingMatrix?.price * this.quantity;

                    if (product.ongoingPromo) {
                        productPromoPricingMatrix = product.ongoingPromo.getMatrixPrice(product.ongoingPromo?.pricingMatrixes, this.quantity);
                        productMarketingPromoPricingMatrix = product.ongoingPromo.getMatrixPrice(product.ongoingPromo?.pricingMatrixes, 1);

                        if (!productPromoPricingMatrix || !productMarketingPromoPricingMatrix) {
                            break;
                        }

                        pricePromo = productPromoPricingMatrix?.price * this.quantity;
                    }
                }

                if (product.productType === ProductTypes.Package) {
                    price = product?.pricing?.price * this.quantity;
                    priceMarketing = product?.pricing?.price * this.quantity;

                    if (product.ongoingPromo) {
                        pricePromo = product?.ongoingPromo?.pricing?.price * this.quantity;
                    }
                }

                pricing.originalPrice = price;
                pricing.originalPriceDisplay = displayPrice(pricing.originalPrice, currency);

                pricing.marketingPrice = priceMarketing;
                pricing.marketingPriceDisplay = displayPrice(pricing.marketingPrice, currency);

                pricing.finalPrice = price;
                pricing.finalPriceDisplay = displayPrice(pricing.finalPrice, currency);

                if (product.ongoingPromo) {
                    pricing.promoPrice = pricePromo;
                    pricing.promoPriceDisplay = displayPrice(pricing.promoPrice, currency);
                    pricing.finalPrice = pricing.promoPrice;
                    pricing.finalPriceDisplay = pricing.promoPriceDisplay;
                    pricing.promoPercent = product.getPromoPercent(pricing.marketingPrice, pricing.promoPrice);
                    pricing.promoPercentDisplay = pricing.promoPercent && `${pricing.promoPercent}%` || null;

                    if (pricing.originalPrice > pricing.promoPrice) {
                        pricing.hasPromo = true;
                    }
                }

                pricing.savingsPercent = this.getPromoPercent(pricing.marketingPrice, pricing.finalPrice);
                pricing.savingsPercentDisplay = pricing.savingsPercent && `${pricing.savingsPercent}%` || null;
                pricing.hasSavings = pricing.savingsPercent > 0;

                break;
            case Types.Voucher:
                voucher = this.item;

                price = changeFromCent(voucher?.pricing?.price) * this.quantity;
                priceMarketing = changeFromCent(voucher?.pricing?.price) * this.quantity;

                if (!voucher) {
                    price = changeFromCent(Number(this.origin.data.voucherValue) * this.quantity);
                    priceMarketing = changeFromCent(Number(this.origin.data.voucherValue) * this.quantity);
                }

                pricing.marketingPrice = priceMarketing;
                pricing.marketingPriceDisplay = displayPrice(pricing.marketingPrice, currency);

                pricing.originalPrice = price;
                pricing.originalPriceDisplay = displayPrice(pricing.originalPrice, currency);

                pricing.finalPrice = price;
                pricing.finalPriceDisplay = displayPrice(pricing.finalPrice, currency);

                pricing.hasPromo = false;
                break;
            case Types.Bundle:
                bundle = this.item;

                price = bundle?.pricing?.price * this.quantity;
                priceMarketing = bundle?.pricing?.price * this.quantity;

                pricing.marketingPrice = priceMarketing;
                pricing.marketingPriceDisplay = displayPrice(pricing.marketingPrice, currency);

                price = bundle?.pricing?.price * this.quantity;
                priceMarketing = bundle?.pricing?.price * this.quantity;

                if (bundle.ongoingPromo) {
                    pricePromo = bundle?.ongoingPromo?.pricing?.price * this.quantity;
                }

                pricing.originalPrice = price;
                pricing.originalPriceDisplay = displayPrice(pricing.originalPrice, currency);

                pricing.finalPrice = price;
                pricing.finalPriceDisplay = displayPrice(pricing.finalPrice, currency);

                if (bundle.ongoingPromo) {
                    pricing.promoPrice = pricePromo;
                    pricing.promoPriceDisplay = displayPrice(pricing.promoPrice, currency);
                    pricing.finalPrice = pricing.promoPrice;
                    pricing.finalPriceDisplay = pricing.promoPriceDisplay;
                    pricing.promoPercent = bundle.getPromoPercent(pricing.marketingPrice, pricing.promoPrice);
                    pricing.promoPercentDisplay = pricing.promoPercent && `${pricing.promoPercent}%` || null;

                    if (pricing.originalPrice > pricing.promoPrice) {
                        pricing.hasPromo = true;
                    }
                }

                pricing.savingsPercent = this.getPromoPercent(pricing.marketingPrice, pricing.finalPrice);
                pricing.savingsPercentDisplay = pricing.savingsPercent && `${pricing.savingsPercent}%` || null;
                pricing.hasSavings = pricing.savingsPercent > 0;
        }

        return pricing;
    };

    getPromoPercent = (price: number, promoPrice: number): number => {
        if (!promoPrice) return 0;

        const difference = price - promoPrice;
        const promoPercent = (difference / price) * 100;

        return Math.round(promoPercent);
    };

    getTrackingProps = (): Record<string, string | number> => {
        if (this.itemType === Types.Offer || this.itemType === Types.Package) {
            return {
                id: this.item.products[0].product.id,
                name: this.item.products[0].product.name,
                price: this.pricing.finalPrice.toFixed(2),
                quantity: this.item.products[0].quantity,
                gender: this.item.products[0].product.gender,
                category: this.item.products[0].product.category,
                itemType: this.itemType,
                dimension3: this.location?.name || null,
                dimension4: this.pricing?.promoPercent || null,
            };
        }

        if (this.itemType === Types.Product) {
            return {
                id: this.item.id,
                name: this.item.name,
                price: this.pricing.finalPrice.toFixed(2),
                quantity: this.quantity,
                gender: this.item.gender,
                category: this.item.category,
                itemType: this.itemType,
                dimension3: this.location?.name || null,
                dimension4: this.pricing?.promoPercent || null,
            };
        }
    };
}

export const fromProduct = (product: Product, quantity: number, currency: Currency): CartItem => {
    return new CartItem(
        {
            id: 'fake',
            createdAt: moment().toISOString(),
            itemType: Types.Product,
            item: product.origin,
            quantity,
            data: null,
            location: null,
            isAccepted: true,
        },
        currency,
    );
};

export const fromBundle = (bundle: Bundle, quantity: number, currency: Currency): CartItem => {
    return new CartItem(
        {
            id: 'fake',
            createdAt: moment().toISOString(),
            itemType: Types.Bundle,
            item: bundle.origin,
            quantity,
            data: null,
            location: null,
            isAccepted: true,
        },
        currency,
    );
};
