export default {
    fontPrimary: 'Lato, Arial, sans-serif',
    fontLight: '300',
    fontRegular: '400',
    fontBold: '700',
    fontBlack: '900',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletM: '960px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileSM: '375px',
    mobileS: '360px',
    mobileXS: '281px',

    colorText: '#272626',
    colorTextDark: '#3E3E3E',
    colorTextGray: '#575757',
    colorTextLight: '#7b7b7b',
    colorTextLighter: '#c1c1c1',
    colorTextPrimary: '#BA9449',

    colorBackground: '#F0F4F5',
    colorBackgroundBorder: '#CCCCCC',
    colorBackgroundBorderLight: '#ececec',

    colorContrast: '#06808E',
    colorContrastLighter: '#0bb6c9',
    colorInfo: '#1cb3b8',
    colorSuccess: '#62b81c',
    colorWarning: '#eb8817',
    colorAlert: '#b8211c',

    colorGreen: '#BCC7AB',
    colorGreenDarker: '#819367',
    colorWhite: '#FFFFFF',
    colorGold: '#b9944a',
    colorGray: '#646060',

    colorBackgroundContrast: '#FFFFFF',
    colorBackgroundContrastDark: '#f3f3f3',
    colorBackgroundContrastDarker: '#c3c3c3',
    colorBackgroundContrastDarkest: '#949494',
    colorBackgroundContrastBorder: '#EEEEEE',
    colorBackgroundContrastBorderDarker: '#CCCCCC',

    firstColorSpinner: '#CFB799',
    secondColorSpinner: '#C19A6A',
    thirdColorSpinner: '#BA9449',

    backgroundGoldGradient: 'transparent linear-gradient(98deg, #D0BC94 0%, #B9944A 100%) 0% 0% no-repeat padding-box',
    backgroundGoldGradientReverse: 'transparent linear-gradient(270deg, #D0BC94 0%, #B9944A 37%, #B9944A 77%, #B9944A 100%) 0% 0% no-repeat padding-box;',
    backgroundButtonGradient: 'transparent linear-gradient(284deg, #b9944a 0%, #d0bc94 100%) 0% 0% no-repeat padding-box',
    backgroundButtonGreyGradient: ' transparent linear-gradient(282deg, #ADADAD 0%, #908F8F 100%) 0% 0% no-repeat padding-box;',

    sectionPadding: '4em 0',
    sectionPaddingMobile: '2em 0',
};
