import ApiVoucher from 'types/api/Voucher';

import { Types as CartItemTypes } from 'models/CartItem';
import Model from 'models/Model';
import VoucherLocale from 'models/VoucherLocale';
import VoucherPricing from 'models/VoucherPricing';

export enum Types {
    Online = 'online',
    Offline = 'offline',
}

export default class Voucher implements Model {
    id: string;
    name: string;
    validDays: string;
    locale: VoucherLocale;
    pricing: VoucherPricing;
    type: CartItemTypes;
    currency: string;

    constructor(data: ApiVoucher) {
        this.id = data.id;
        this.name = data.name || data.data.name;
        this.validDays = data.validDays;
        this.locale = data.locale && new VoucherLocale(data.locale) || null;
        this.pricing = data.pricing && new VoucherPricing(data.pricing) || null;
        this.type = CartItemTypes.Voucher;
        this.currency = data?.pricing?.currency?.code;
    }

    getTrackingProps = (): Record<string, string | number> => {
        return {
            id: this.id,
            name: this.name,
            price:  '',
            gender: null,
            category: CartItemTypes.Voucher,
            itemType: CartItemTypes.Voucher,
            quantity: 1,
        };
    };
}
