import { useContext } from 'react';
import getConfig from 'next/config';
import { translations } from 'locales';
import { config } from 'locales/common/localeConfig';
import { Locale } from 'locales/types/locale';

import { CookieKeys } from 'types/cookies';
import { Locales } from 'types/locale';

import { deserialize, replaceObjectVariables } from 'utils/object';
import { getProvider } from 'services/Cookies';

import LocaleContext from 'components/context/Locale';

const { publicRuntimeConfig } = getConfig();
const defaultLocale: Locales = publicRuntimeConfig.APP_LOCALE;

const langMap = {
    [defaultLocale]: {
        translations,
        config,
    },
};

export function getLocale(locale: Locales = null): Locale {
    const finalLocale = (locale || defaultLocale).toLowerCase();
    const trns = langMap[finalLocale]?.translations;
    const result = deserialize({ ...trns });

    return replaceObjectVariables({ ...result }, { ...result });
}

export function getLocaleConfig(locale: Locales = null): typeof config {
    const finalLocale = (locale || defaultLocale).toLowerCase();

    return langMap[finalLocale]?.config;
}

export function getRoutes(locale: Locales = null): { [key: string]: string } {
    const finalLocale = (locale || defaultLocale).toLowerCase();
    const trns = langMap[finalLocale]?.translations;
    const result = deserialize({ ...trns });
    const replacedResult = replaceObjectVariables({ ...result }, { ...result });

    return replacedResult?.routes;
}

export const select = (translationsObject: object): any => {
    let selectedLang = null;

    try {
        selectedLang = useContext(LocaleContext)?.locale;
    } catch (error) {
        selectedLang = getProvider().get(CookieKeys.NextLocale) || null;
    }

    let selectedObject = null;
    if (selectedLang) {
        selectedObject = translationsObject[selectedLang?.toLowerCase()];
    }

    if (!selectedObject && defaultLocale) {
        selectedObject = translationsObject[defaultLocale?.toLowerCase()];
    }

    if (!selectedObject || Object.keys(selectedObject).length === 0) {
        return {};
    }

    const firstKey = Object.keys(selectedObject)[0];
    return selectedObject[firstKey];
};
