import translationsBlueprint from 'locales/models/pl.json';

import ApiBodyPartGroup from 'types/api/BodyPartGroup';
import { Locales } from 'types/locale';

import BodyPartGroupLocale from 'models/BodyPartGroupLocale';
import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

export enum Types {
    Top = 'top',
    Bottom = 'bottom',
    Face = 'face',
}

const translationsModels: typeof translationsBlueprint.comp = selectLocale({
    [Locales.En]: require('locales/models/en.json'),
    [Locales.Pl]: require('locales/models/pl.json'),
});

export default class BodyPartGroup implements Model {
    id: string;
    name: string;
    locales: BodyPartGroupLocale[];
    type: Types;

    constructor(data: ApiBodyPartGroup) {
        this.id = data.id;
        this.name = data.name;
        this.locales = Array.isArray(data.locales) && data.locales.map(locale => new BodyPartGroupLocale(locale));
        this.type = this.getType();
    }

    getType = () => {
        switch (this.name) {
            case translationsModels?.bodyPartGroup?.type?.top?.label:
                return Types.Top;
            case translationsModels?.bodyPartGroup?.type?.bottom?.label:
                return Types.Bottom;
            case translationsModels?.bodyPartGroup?.type?.face?.label:
                return Types.Face;
            default:
                break;
        }
    };
}
