import ApiArticleAuthor from 'types/api/ArticleAuthor';

import FileUpload, { getPlaceholder } from 'models/FileUpload';
import Model from 'models/Model';

export default class ArticleAuthor implements Model {
    id: string;
    avatar: FileUpload;
    name: string;
    position: string;
    bio: string;
    specialization: string;

    constructor(data: ApiArticleAuthor) {
        this.id = data.id;
        this.avatar = data.avatar && new FileUpload(data.avatar) || getPlaceholder();
        this.name = data.name;
        this.position = data.position;
        this.bio = data.bio;
        this.specialization = data.specialization;
    }
}
