import { getKeyValueByString } from 'utils/object';
import { parseToQueryString } from 'utils/querystring';

export function shorten(string: string, length: number, addDots = false): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    let newString = string.substring(0, length);
    if (string.length > length && addDots) newString = newString + '...';

    return newString;
}

export function withVariables(string: string, variables = {}, queryObject = {}, hash = ''): string {
    if (!string || typeof string !== 'string') {
        return '';
    }

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = ':variableKey'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    Object.keys(variables)
        .forEach(variableKey => {
            const pattern = '[variableKey]'.replace('variableKey', variableKey);

            string = string.replace(pattern, variables[variableKey]);
        });

    string = string
        .replace('(', '')
        .replace(')', '');

    return parseToQueryString(string, queryObject) + hash;
}


export function isJsonString(jsonString: string): boolean {
    try {
        const o = JSON.parse(jsonString);
        if (o && typeof o === 'object') {
            return o;
        }
    }
    catch (e) {
        // Ignore
    }

    return false;
}

export function slugify(text: string): string {
    text = (text || '')
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    return text
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export function replaceVariables(string: string, values: object): string {
    const regexp = new RegExp('{{.+?}}', 'g');
    const matches = string.match(regexp);

    if (!Array.isArray(matches)) {
        return string;
    }

    matches.forEach(match => {
        let path = match.match(/\{\{(.*)\}\}/g)[0];
        path = path
            .replace('{', '')
            .replace('{', '')
            .replace('}', '')
            .replace('}', '');

        let value = getKeyValueByString(values, path);

        if (typeof value === 'undefined') {
            console.error(`Can't find value for variable ${match} at path ${path}`);
            value = '';
        }

        string = string.replace(match, value);
    });

    return string;
}

// eslint-disable-next-line/* eslint-disable @typescript-eslint/no-unused-vars */
export function regexString(regex: { exec: (arg: string) => any[]; }, string: string): string[] {
    if (!string) return;

    let match = null;
    const regexedString = [];

    while ((match = regex.exec(string)) != null) {
        regexedString.push(match[1]);
    }

    return regexedString;
}

export function stripTags(string: string, regexOptions: string[] = []): string {
    let regexExp: RegExp = null;

    if (regexOptions.length > 0) {
        regexExp = new RegExp(`<[^>]*>|${regexOptions.join('|') || ''}`, 'g');
    } else {
        regexExp = new RegExp('<[^>]*>', 'g');
    }

    return string.replace(regexExp, '');
}

export function capitaliseWords(string: String) {
    if (!string) {
        return '';
    }
    const splitWords = string.trim().split(/\s+/);
    const capitalisedWords = splitWords.map(item => {
        return item.charAt(0).toUpperCase() + item.slice(1);
    });
    return capitalisedWords.join(' ');
};

export function formatHtml(html: string): string {
    const elements = html.split('\n');
    const result: string[] = [];

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        let outerHTML = element.trim();

        if (outerHTML.includes('img') && outerHTML.includes('alt')) {
            const nextImageWihoutSeparate = elements[i + 2]?.includes('img');
            const altLink = outerHTML.match(/<img[^>]+alt="([^"]*)"/)?.[1];

            if (altLink && /(https|www)/gm.test(altLink)) {
                const alt = altLink
                    ?.replace(/^https?:\/\//, '')
                    ?.replace(/[^\w\s]/g, ' ')
                    ?.replace(/(.)com.*/gm, '');

                outerHTML = `<a href="${altLink}" style="display: inline-block;${nextImageWihoutSeparate ? ' margin-right: 1em' : ''}" target="_blank" >` + outerHTML.replace(/alt="([^"]*)"/, `alt="${alt}"`) + '</a>';
            }
        }

        result.push(outerHTML);
    }

    return result.join('\n');
}
