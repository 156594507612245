import ApiArticleLocale from 'types/api/ArticleLocale';

import Model from 'models/Model';

export default class LocalePromoBar implements Model {
    id: string;
    promoBarVisible: boolean;
    promoBarHeadline?: string;
    promoBarSubheadline?: string;
    promoBarButtonMainLabel?: string;
    promoBarButtonMainUrl?: string;
    promoBarButtonSideLabel?: string;
    promoBarButtonSideUrl?: string;

    constructor(data: ApiArticleLocale) {
        this.id = data.id;
        this.promoBarVisible = data.promoBarVisible;
        this.promoBarHeadline = data.promoBarHeadline;
        this.promoBarSubheadline = data.promoBarSubheadline;
        this.promoBarButtonMainLabel = data.promoBarButtonMainLabel;
        this.promoBarButtonMainUrl = data.promoBarButtonMainUrl;
        this.promoBarButtonSideLabel = data.promoBarButtonSideLabel;
        this.promoBarButtonSideUrl = data.promoBarButtonSideUrl;
    }
}
