import ApiArticleCategoryLocale from 'types/api/ArticleCategoryLocale';

import Model from 'models/Model';

export default class ArticleCategoryLocale implements Model {
    id: string;
    name: string;
    slug: string;
    metaTitle?: string;
    metaDescription?: string;

    constructor(data: ApiArticleCategoryLocale) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
    }
}
