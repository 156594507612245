import ApiCity from 'types/api/Voivodeship';

import Model from 'models/Model';


export default class Voivodeship implements Model {
    id: string;
    name: string;
    slug: string;

    constructor(data: ApiCity) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
    }
}