import { HYDRATE } from 'next-redux-wrapper';

import { ApiResources } from 'types/apiResources';
import Element from 'types/redux/Element';

import Cart from 'models/Cart';
import CartItem from 'models/CartItem';

import { ELEMENT_MARKUP, SUCCESS_FLAG } from 'consts/redux';

import * as types from './types';

export interface State {
    data: Cart;
    mapById: {
        [key: string]: Element<CartItem>;
    };
}

export const initialState = {
    data: null,
    mapById: {},
};

export default function CartsReducer(state: State = initialState, action: any): any {
    const replaceStateWithElement = (identifier = 'id') => ({
        ...state,
        mapById: {
            ...state.mapById,
            [action.params[identifier]]: {
                ...ELEMENT_MARKUP,
                ...(state.mapById[action.params[identifier]] || {}),
                data: new CartItem(action.payload.data[ApiResources.CartItem], action.params?.currency),
            },
        },
    });

    switch (action.type) {
        case HYDRATE:
            // Attention! This will overwrite client state! Real apps should use proper reconciliation.
            return { ...state, ...action.payload };

        case types.GET_CART + '_' + SUCCESS_FLAG:
        case types.SET_DISCOUNT_CODE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                data: new Cart(action.payload?.data[ApiResources.Cart]),
            };

        case types.SET_CART:
            return {
                ...state,
                data: action?.payload?.cart,
            };

        case types.CREATE_CART_ITEM + '_' + SUCCESS_FLAG:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: [
                        ...state.data.items,
                        new CartItem(action.payload.data[ApiResources.CartItem], action.params?.currency),
                    ],
                },
            };

        case types.REMOVE_CART_ITEM + '_' + SUCCESS_FLAG:
            return {
                ...state,
                data: {
                    ...state.data,
                    items: state.data.items.filter(item => item.id !== action.params?.id),
                },
            };

        case types.UPDATE_CART_ITEM + '_' + SUCCESS_FLAG:
            return { ...replaceStateWithElement() };


        default:
            return state;
    }
}
