import ApiProductBodyPart from 'types/api/ProductBodyPart';

import BodyPart from 'models/BodyPart';
import Model from 'models/Model';

export default class ProductBodyPart implements Model {
    id: string;
    bodyPart: BodyPart;

    constructor(data: ApiProductBodyPart) {
        this.id = data.id;
        this.bodyPart = data.bodyPart && new BodyPart(data.bodyPart);
    }
}
