import { HYDRATE } from 'next-redux-wrapper';

import { ApiResources } from 'types/apiResources';

import Market from 'models/Market';

import { SUCCESS_FLAG } from 'consts/redux';

import * as types from './types';

export interface State {
    market?: Market;
}

export const initialState = {
    market: null,
};

export default function MarketsReducer(state: State = initialState, action: any): any {
    switch (action.type) {
        case HYDRATE:
            // Attention! This will overwrite client state! Real apps should use proper reconciliation.
            return { ...state, ...action.payload };

        case types.SET:
            return {
                ...state,
                market: action.payload.market,
            };

        case types.SINGLE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                market: new Market(action.payload?.data[ApiResources.Market]),
            };

        default:
            return state;
    }
}
