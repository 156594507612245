import ApiProductInfo from 'types/api/ProductInfo';

import Model from 'models/Model';

export enum SectionTypes {
    Indications = 'indications',
    Contraindications = 'contraindications',
    Benefits = 'benefits',
}
export default class ProductInfo implements Model {
    id: string;
    section: SectionTypes;
    description: string;
    order: number;

    constructor(data: ApiProductInfo) {
        this.id = data.id;
        this.section = data.section || null;
        this.description = data.description || null;
        this.order = data.order;
    }
}
