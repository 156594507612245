import ApiCurrency from 'types/api/Currency';

import Model from 'models/Model';

export default class Currency implements Model {
    origin: ApiCurrency;
    id: string;
    name: string;
    code: string;
    symbol: string;
    isPrefix: boolean;

    constructor(data: ApiCurrency) {
        this.origin = data;
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
        this.symbol = data.symbol;
        this.isPrefix = data.isPrefix;
    }
}