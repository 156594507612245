import ApiFileUpload from 'types/api/FileUpload';

import Model from 'models/Model';

import { IMAGE_PLACEHOLDER } from 'consts/images';

export enum Types {
    ProdctImage = 'productImage',
    OfferImage = 'offerImage',
    VoucherImage = 'voucherImage',
    ArticleAuthorImage = 'articleAuthorImage',
    CmsSectionImage = 'cmsSectionImage',
    LocationImage = 'locationImage',
    ArticleContentImage = 'articleContentImage',
    UserHealthPollFile = 'userHealthPollFile',
    ArticleCategoryCoverImage = 'articleCategoryCoverImage',
    ProductComparisonImage = 'productComparisonImage',
    ComplaintFile = 'complaintFile',
}


export default class FileUpload implements Model {
    id: string;
    imageUrl: string;
    alt?: string;
    title?: string;
    mimeType?: string;
    fileName?: string;
    originalName?: string;
    putUrl?: string;
    getUrl?: number;
    storagePath?: string;

    constructor(data: ApiFileUpload) {
        this.id = data.id;
        this.imageUrl = data.imageUrl;
        this.originalName = data.originalName;
        this.alt = data.alt;
        this.title = data.title;
        this.mimeType = data.mimeType;
        this.putUrl = data.putUrl;
        this.fileName = data.fileName;
        this.getUrl = data.getUrl;
        this.storagePath = data.storagePath;
    }

    sendToS3 = (file: File, callbacks?: { progress?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => any }): Promise<FileUpload> => {
        return new Promise((resolve, reject) => {
            try {
                const data = new FormData();
                data.append('file', file);

                const req = new XMLHttpRequest();
                req.open('PUT', this.putUrl);
                req.setRequestHeader('Access-Control-Allow-Origin', '*');
                req.setRequestHeader('Content-Disposition', `inline; filename="${this.fileName}"`);
                req.setRequestHeader('Cache-Control', 'max-age=86400, public');
                req.setRequestHeader('Pragma', 'public');
                req.setRequestHeader('Content-Type', this.mimeType);
                req.addEventListener('load', () => {
                    resolve(this);
                });
                req.upload.addEventListener('progress', (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
                    if(callbacks?.progress) {
                        callbacks?.progress(event);
                    }
                });
                req.send(file);
            } catch (error: any) {
                reject(error);
            }
        });
    };
}

export const getPlaceholder = (): FileUpload => {
    return new FileUpload({
        id: `fake-${Date.now()}`,
        imageUrl: IMAGE_PLACEHOLDER,
        alt: null,
        title: null,
        mimeType: null,
        fileName: null,
        originalName: null,
        putUrl: null,
        getUrl: null,
        storagePath: null,
    });
};
