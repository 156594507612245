import ApiLocale from 'types/api/Locale';

import Model from 'models/Model';

export default class Locale implements Model {
    id: string;
    name: string;
    code: string;
    isoCode: string;

    constructor(data: ApiLocale) {
        this.id = data.id;
        this.name = data.name;
        this.code = data.code?.toLowerCase();
        this.isoCode = data.isoCode;
    }
}