import ApiProductComparison, { BeforeAfterListImages } from 'types/api/ProductComparison';

import Model from 'models/Model';

export default class ProductComparison implements Model {
    id: string;
    headline: string;
    subheadline: string;
    order?: number;
    imageBefore: string;
    imageAfter: string;
    imagesBeforeAfterList?: BeforeAfterListImages[];

    constructor(data: ApiProductComparison) {
        this.id = data.id;
        this.headline = data.title || null;
        this.subheadline = data.content || null;
        this.order = data.order;
        this.imageBefore = data.imageBefore?.imageUrl || '';
        this.imageAfter = data.imageAfter?.imageUrl;
        this.imagesBeforeAfterList = data?.imagesBeforeAfterList || [];
    }
}
