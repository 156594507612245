import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';
import { LocalStorageKeys } from 'types/localStorage';
import { ActionResponse } from 'types/redux/ActionResponse';
import { ReduxRequestPassOptions } from 'types/request';

import Visitor from 'models/Visitor';

import { withVariables } from 'utils/string';
import { reduxRequest as request } from 'services/Api';
import LocalStorage from 'services/LocalStorage';

import * as types from './types';

export interface SubmitParams {
    uniqueId: string;
}
export const submit = (params?: SubmitParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.CREATE,
            method: 'POST',
            path: withVariables(Endpoints.PublicVisitors, params),
            params,
            requestParams: params,
            ...requestParams,
        })
    )
        .then(response => {
            const data = response?.payload?.data;
            const resource = data && data[ApiResources.Visitor];
            if(resource) {
                LocalStorage.setItem(LocalStorageKeys.Visitor, resource);
            }
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface SetParams {
    visitor: Visitor;
}
export const set = (params?: SetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch({
        type: types.SET,
        payload: params,
    });
};
