import moment, { Moment } from 'moment-timezone';

import ApiBundleInfo from 'types/api/BundleInfo';

import FileUpload from 'models/FileUpload';
import Locale from 'models/Locale';
import Model from 'models/Model';

export interface LocaleData {
    id: string;
    createdAt: Moment;
    content: string;
    locale: Locale;
}
export default class BundleInfo implements Model {
    id: string;
    enabled: boolean;
    order: number;
    icon: FileUpload;
    maxOrder: number;
    locales: LocaleData[];

    constructor(data: ApiBundleInfo) {
        this.id = data.id;
        this.enabled = data.enabled;
        this.order = data.order;
        this.icon = data.icon && new FileUpload(data.icon);
        this.maxOrder = data.maxOrder;
        this.locales = Array.isArray(data.locales) && data.locales.map(locale => ({
            id: locale.id,
            createdAt: locale.createdAt && moment.utc(locale.createdAt),
            locale: locale.locale && new Locale(locale.locale),
            content: locale.content,
        }));
    }
}