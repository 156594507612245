import ApiCartItemData from 'types/api/CartItemData';

import Location from 'models/Location';
import { Types as VoucherTypes } from 'models/Voucher';

import { changeFromCent } from 'utils/math';

export default class CartItemData {
    voucherType: VoucherTypes;
    voucherValue: number;
    voucherPrice: number;
    voucherFirstName: string;
    voucherLastName: string;
    voucherEmail: string;
    voucherText: string;
    voucherLocationId: string;
    voucherLocation: Location;
    voucherName: string;

    constructor(data: ApiCartItemData) {
        this.voucherType = data.voucherType;
        this.voucherValue = data.voucherValue;
        this.voucherPrice = data.voucherPrice;
        this.voucherFirstName = data.voucherFirstName;
        this.voucherLastName = data.voucherLastName;
        this.voucherEmail = data.voucherEmail;
        this.voucherText = data.voucherText;
        this.voucherLocationId = data.voucherLocationId;
        this.voucherLocation = data.voucherLocation && new Location(data.voucherLocation);
        this.voucherName = `Voucher ${changeFromCent(this.voucherValue)}`;
    }
}
