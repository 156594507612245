import moment, { Moment } from 'moment-timezone';

import ApiUser from 'types/api/User';

import Location from 'models/Location';
import { Timezone } from 'models/Market';
import Model from 'models/Model';
import UserAgree from 'models/UserAgree';

import { getFormattedDate } from 'utils/date';

export interface UserInvoice {
    address: string;
    city: string;
    companyName: string;
    postalCode: string;
    vatId: string;
    country?: string;
    createdAt?: Moment;
}

export default class User implements Model {
    id: string;
    dateBirth: Moment;
    dateBirthDisplay: string;
    email: string;
    firstName: string;
    gender: string;
    hasPassword: boolean;
    isInfluencer: boolean;
    isFirstEvent: boolean;
    lastName: string;
    phone: string;
    phonePrefix: string;
    role: string;
    prefferedLocation: Location;
    hasInvoiceData: boolean;
    userAgrees: UserAgree[];
    fullName: string;
    userInvoice: UserInvoice;

    constructor(data: ApiUser) {
        this.id = data.id;
        this.dateBirth = data.dateBirth && moment.utc(data.dateBirth).tz(Timezone);
        this.dateBirthDisplay = this.dateBirth && getFormattedDate(this.dateBirth);
        this.email = data.email;
        this.firstName = data.firstName;
        this.gender = data.gender;
        this.hasPassword = data.hasPassword;
        this.isInfluencer = data.isInfluencer;
        this.isFirstEvent = data.isFirstEvent;
        this.lastName = data.lastName;
        this.phone = data.phone;
        this.phonePrefix = data.phonePrefix;
        this.role = data.role;
        this.hasInvoiceData = data.hasInvoiceData;
        this.prefferedLocation = data.prefferedLocation && new Location(data.prefferedLocation);
        this.fullName = this.getFullName();
        this.userInvoice = data.userInvoice;
        this.userAgrees = Array.isArray(data.userAgrees)
            ? data.userAgrees.map(userAgree => new UserAgree(userAgree))
            : [];
    }

    getFullName = (): string => {
        return `${this.firstName || ''} ${this.lastName || ''}`;
    };
}
