import { Context, createContext, useState } from 'react';

export interface UserPanelContextType {
    selectedView: string;
    setSelectedView: (nextValue: string) => void,
};

const UserPanelContext: Context<UserPanelContextType> = createContext<UserPanelContextType>({
    selectedView: null,
    setSelectedView: () => null,
});

export const UserPanelContextProvider = ({ children }) => {
    const [selectedView, setSelectedView] = useState<string>(null);

    return (
        <UserPanelContext.Provider value={{ selectedView, setSelectedView }}>
            {children}
        </UserPanelContext.Provider>
    );
};

export default UserPanelContext;