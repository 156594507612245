import CartItem from 'models/CartItem';
import Model from 'models/Model';

enum ItemType {
    Product = 'product',
    Offer = 'offer',
    Package = 'package',
}

interface DataByProductType {
    id: string;
    name: string;
    quantity: number;
    gender: string;
    category: string;
    price: number;
    itemType: string;
}

export default class GtmCartItem implements Model {
    id: string;
    cartItemData: DataByProductType;

    constructor(data: CartItem) {
        this.id = data.id;
        this.cartItemData = this.getDataByItemType(data.itemType, data);
    }

    getDataByItemType = (type: string, i: CartItem): DataByProductType => {
        if (type === ItemType.Offer || type === ItemType.Package) {
            return {
                id: i.item.products[0].product.id,
                name: i.item.products[0].product.name,
                quantity: i.item.products[0].quantity,
                price: i.pricing.finalPrice,
                gender: i.item.products[0].product.gender,
                category: i.item.products[0].product.category,
                itemType: i.itemType,
            };
        }

        if (type === ItemType.Product) {
            return {
                id: i.item.id,
                name: i.item.name,
                quantity: i.quantity,
                price: i.pricing.finalPrice,
                gender: i.item.gender,
                category: i.item.category,
                itemType: i.itemType,
            };
        }
    };
}
