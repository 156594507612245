export const SET_AUTH_TOKEN = 'user/SET_AUTH_TOKEN';
export const LOGIN = 'user/LOGIN';
export const ASSIGN_CODE = 'user/ASSIGN_CODE';
export const LOGOUT = 'user/LOGOUT';
export const PROFILE_GET = 'user/PROFILE_GET';
export const INVOICE = 'user/INVOICE';
export const PASSWORD_RESET = 'user/PASSWORD_RESET';
export const PUBLIC_PASSWORD_CHANGE = 'user/PUBLIC_PASSWORD_CHANGE';
export const PASSWORD_CHANGE = 'user/PASSWORD_CHANGE';
export const PASSWORD_SET = 'user/PASSWORD_SET';
export const REGISTER = 'user/REGISTER';
export const PROFILE_UPDATE = 'user/PROFILE_UPDATE';
export const PROFILE_GENDER_UPDATE = 'user/PROFILE_GENDER_UPDATE';
export const SET_USER_AGREES = 'user/SET_USER_AGREES';
export const PROFILE_DELETE = 'user/PROFILE_DELETE';
