import Currency from 'models/Currency';

export function formatPrice(price: number = 0): number {
    return Math.round(price * 100) / 100;
}

export function displayPrice(price: number = 0, currency?: Currency): string {
    const base = `${formatPrice(price)}`;
    if (!currency) return base;
    if (currency?.isPrefix) return `${currency.symbol} ${base}`;
    return `${base} ${currency.symbol}`;
}

export function changeToCent(price: number = 0): number {
    return price * 100;
}

export function changeFromCent(price: number = 0): number {
    return formatPrice(price / 100);
}


export function round(number: number, places = 2): number {
    const pow = Math.pow(10, places);
    return Math.round((number + Number.EPSILON) * pow) / pow;
}

export function isOdd(number: number): boolean {
    return number % 2 !== 0;
}
