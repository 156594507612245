import ApiLocationLocale from 'types/api/LocationLocale';

import Model from 'models/Model';

export default class LocationLocale implements Model {
    id: string;
    name: string;
    headlineSeo: string;
    slug: string;
    description: string;
    directions: string;
    metaTitle?: string;
    metaDescription?: string;

    constructor(data: ApiLocationLocale) {
        this.id = data.id;
        this.name = data.name;
        this.headlineSeo = data.headlineSeo;
        this.slug = data.slug;
        this.description = data.description;
        this.directions = data.directions;
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
    }
}
