import ApiProductPricing from 'types/api/ProductPricing';

import Currency from 'models/Currency';
import Model from 'models/Model';

import { changeFromCent, displayPrice } from 'utils/math';

export default class ProductPricing implements Model {
    id: string;
    currency: Currency;
    price: number;
    priceDisplay: string;

    constructor(data: ApiProductPricing) {
        const newCurrency = data.currency && new Currency(data.currency);
        const price = changeFromCent(data.price);

        this.id = data.id;
        this.currency = newCurrency;
        this.price = price;
        this.priceDisplay = displayPrice(price, newCurrency);
    }
}
