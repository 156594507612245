import ApiCity from 'types/api/City';
import { Option } from 'types/options';

import Model from 'models/Model';
import Voivodeship from 'models/Voivodeship';

export default class City implements Model {
    id: string;
    name: string;
    slug: string;
    voivodeship: Voivodeship;

    constructor(data: ApiCity) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.voivodeship = data.voivodeship && new Voivodeship(data.voivodeship) || null;
    }

    getOption = (): Option<City> => {
        return {
            value: this,
            label: this.name,
        };
    };
}