import { HYDRATE } from 'next-redux-wrapper';

import { ApiResources } from 'types/apiResources';
import { CookieKeys } from 'types/cookies';

import Market from 'models/Market';
import User from 'models/User';

import { FAIL_FLAG, INIT_FLAG, SUCCESS_FLAG } from 'consts/redux';
import { getProvider } from 'services/Cookies';

import * as types from './types';

export interface State {
    authToken?: string;
    profile?: User;
    market?: Market;
}

export const initialState = {
    authToken: null,
    profile: null,
    market: null,
};

export default function UserReducer(state: State = initialState, action: any): any {
    const authToken = getProvider().get(CookieKeys.AuthToken);

    switch (action.type) {
        case HYDRATE:
            // Attention! This will overwrite client state! Real apps should use proper reconciliation.
            return {
                ...state,
                ...action.payload,
                authToken: state.authToken || action?.payload?.authToken || authToken || null,
            };

        case types.SET_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload.authToken,
            };

        case types.LOGIN + '_' + INIT_FLAG:
            return {
                ...state,
                authToken: null,
            };

        case types.LOGIN + '_' + FAIL_FLAG:
            return {
                ...state,
                authToken: null,
            };

        case types.LOGOUT + '_' + SUCCESS_FLAG:
            return {
                ...state,
                authToken: null,
                profile: null,
            };
        case types.LOGOUT + '_' + FAIL_FLAG:
        case types.PROFILE_DELETE + '_' + FAIL_FLAG:
            return {
                ...state,
                authToken: null,
                profile: null,
            };

        case types.LOGIN + '_' + SUCCESS_FLAG:
        case types.PROFILE_GET + '_' + SUCCESS_FLAG:
        case types.PASSWORD_SET + '_' + SUCCESS_FLAG:
        case types.PUBLIC_PASSWORD_CHANGE + '_' + SUCCESS_FLAG:
        case types.PROFILE_UPDATE + '_' + SUCCESS_FLAG:
        case types.PROFILE_GENDER_UPDATE + '_' + SUCCESS_FLAG:
        case types.SET_USER_AGREES + '_' + SUCCESS_FLAG:
        case types.INVOICE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                authToken: action.payload?.data?.authToken || state.authToken,
                profile: new User(action.payload.data[ApiResources.Me]),
            };

        default:
            return state;
    }
}
