import { Locales } from 'types/locale';

import { PaymentProvider } from 'models/PaymentTransaction';

export const config = {
    en: {
        defaultPaymentMethod: PaymentProvider.PayPal,
        defaultLocale: Locales.En,
        defaultLogoLightUrl: '/theme/images/logo-envie-light.svg',
        defaultLogoDarkUrl: '/theme/images/logo-envie-dark.svg',
        cookieDescription: 'By using the website, you consent to the use of cookies, to the extent corresponding to the configuration of your browser.',
        cookiePrivacyPolicy: 'Learn more.',
        cookieButtonText: 'I Agree',
    },
    pl: {
        defaultPaymentMethod: PaymentProvider.Przelewy24,
        defaultLocale: Locales.Pl,
        defaultLogoLightUrl: '/theme/images/logo-white-depi.svg',
        defaultLogoDarkUrl: '/theme/images/logo-gold-depi.svg',
        cookieDescription: 'Korzystając ze strony wyrażasz zgodę na wykorzystywanie plików cookies, w zakresie odpowiadającym konfiguracji Twojej przeglądarki.',
        cookiePrivacyPolicy: 'Dowiedz się więcej.',
        cookieButtonText: 'OK, rozumiem',
    },
};
