import { Routes } from 'types/routes';

export function getWrapperName(pathname: string): string {
    pathname = pathname.replace('/pl', '');

    switch (true) {
        case pathname.startsWith(Routes.PrefixPanel):
            return Routes.PrefixPanel;
        case pathname.startsWith(Routes.PrefixAccount):
            return Routes.PrefixAccount;
        default:
            return 'public';
    }
}
