import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';
import ListCollection from 'types/redux/ListCollection';

import DiscountCode from 'models/DiscountCode';

import { LIST_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

interface Product {
    productId: string;
    quantity: number;
}

export interface ListParams {
    page?: number;
    perPage?: number;
    hasUseLimitReached?: boolean;
    code: string;
    userId?: string;
    products?: Product[];
    bundles?: {
        bundleId: string;
        quantity: number;
    }[];
}

export const discountCodeVerify = async (params?: ListParams): Promise<ListCollection<DiscountCode>> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicDiscountCodeVerify, params),
        requestParams: params,
        params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.DiscountCode];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        isLoaded: true,
        elements: [{
            ...data[ApiResources.DiscountCode],
            cart: data[ApiResources.Cart],
        }],
        meta: response.meta,
    };
};
