import { createContext } from 'react';

export interface DeviceContext {
    isMobile: boolean;
    isBot: boolean;
    isMobileNavigation: boolean;
}

const initialState: DeviceContext = {
    isMobile: false,
    isBot: false,
    isMobileNavigation: false,
};

const DeviceContext = createContext(initialState);
export default DeviceContext;
