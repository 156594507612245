import ApiBodyPart from 'types/api/BodyPart';

import BodyPartGroup from 'models/BodyPartGroup';
import Model from 'models/Model';

export default class BodyPart implements Model {
    id: string;
    name: string;
    locale: {
        id: string;
        name: string;
        slug: string;
    };
    bodyPartGroup?: BodyPartGroup;

    constructor(data: ApiBodyPart) {
        this.id = data.id;
        this.name = data.name;
        this.locale = data.locale;
        this.bodyPartGroup = data.bodyPartGroup && new BodyPartGroup(data.bodyPartGroup);
    }
}
