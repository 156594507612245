export default `
    #cc--main {
        .cc_div {
            .cloud {
                max-width: 100%;
                
                #c-inr {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                }

                #c-inr-i {
                    width: 100%;
                    padding-right: 0;
                    padding-bottom: 20px;
                }

                #c-bns {
                    min-width: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row-reverse;

                    button {
                        margin: 0 10px !important;
                        width: auto;
                        border: 1px solid #BA9449;

                        &:first-of-type {
                            background-color: #BA9449;

                            &:hover {
                                background-color: #CFB799;
                                border-color: #CFB799;
                            }
                        }

                        &:last-of-type {
                            color: #BA9449;
                            background-color: #fff;
                            border-color: #BA9449;

                            &:hover {
                                color: #fff;
                                background-color: #CFB799;
                                border-color: #CFB799;
                            }
                        }
                    }
                }
            }

            a {
                color: #BA9449;
                text-decoration: none !important;
            }

            a:hover {
                text-decoration: underline;
            }

            #s-cnt {
                #cs {
                    #c-s-in {
                        #s-inr {
                            #s-hdr {
                                #s-c-bnc {

                                    #s-c-bn {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 50px;
                                        background-color: transparent;
                                        outline: none;

                                        &:hover {
                                            background-color: #CFB799;
                                            border-color: #CFB799;

                                            &:after,
                                            &:before {
                                                background-color: #fff;
                                            }
                                        }

                                        &::after,
                                        &::before {
                                            content: '';
                                            position: absolute;
                                            height: 16px;
                                            width: 2px;
                                            background: #000;
                                            border-radius: 1em;
                                            margin: 0 auto;
                                        }

                                        &::after {
                                            transform: rotate(45deg);
                                        }

                                        &::before {
                                            transform: rotate(-45deg);
                                        }
                                    }
                                }
                            }

                            #s-bl {
                                color: #000;

                                .c-bl {
                                    border-radius: 0.35em;
                                    background-color: #efefef;

                                    &:hover {
                                        background-color: #dddddd;
                                    }
                                    
                                    &:not(.b-ex) {
                                        background-color: transparent;
                                        font-size: 14px;
                                        
                                        .p {
                                            text-align: justify;
                                        }
                                    }

                                    .b-bn {
                                        cursor: pointer;
                                        border-radius: 0.35em;

                                        &:hover {
                                            background-color: #dddddd;
                                        }

                                        .b-tl {
                                            border-radius: 0.35em;

                                            &:hover {
                                                background-color: #dddddd;
                                            }
                                        }

                                        .b-tg {
                                            .c-tg {
                                                background-color: #aaa;
                                            }

                                            .c-tgl:checked ~ .c-tg {
                                                background-color: #BA9449;
                                            }
                                        }
                                    }
                                }
                            }

                            #s-bns {
                                display: flex;
                                flex-direction: row-reverse;
                                justify-content: space-between;
                                
                                button {
                                    margin: 0 10px !important;
                                    width: auto;
                                    border: 1px solid #BA9449;

                                    &:first-of-type {
                                        background-color: #BA9449;

                                        &:hover {
                                            background-color: #CFB799;
                                            border-color: #CFB799;
                                        }
                                    }

                                    &:last-of-type {
                                        color: #BA9449;
                                        background-color: #fff;
                                        border-color: #BA9449;

                                        &:hover {
                                            color: #fff;
                                            background-color: #CFB799;
                                            border-color: #CFB799;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
