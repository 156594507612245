import moment, { Moment } from 'moment-timezone';

import ApiLocationOpenTime from 'types/api/LocationOpenTime';

import { Timezone } from 'models/Market';
import ModelOpenTime, { weekDayToOpenTime } from 'models/ModelOpenTime';

import { TIME_API_FORMAT } from 'consts/dates';

export default class LocationOpenTime implements ModelOpenTime {
    id: string;
    mondayStart: string;
    mondayEnd: string;
    mondayClosed: boolean;
    mondayAllDay: boolean;
    tuesdayStart: string;
    tuesdayEnd: string;
    tuesdayClosed: boolean;
    tuesdayAllDay: boolean;
    wednesdayStart: string;
    wednesdayEnd: string;
    wednesdayClosed: boolean;
    wednesdayAllDay: boolean;
    thursdayStart: string;
    thursdayEnd: string;
    thursdayClosed: boolean;
    thursdayAllDay: boolean;
    fridayStart: string;
    fridayEnd: string;
    fridayClosed: boolean;
    fridayAllDay: boolean;
    saturdayStart: string;
    saturdayEnd: string;
    saturdayClosed: boolean;
    saturdayAllDay: boolean;
    sundayStart: string;
    sundayEnd: string;
    sundayClosed: boolean;
    sundayAllDay: boolean;

    constructor(data: ApiLocationOpenTime) {
        this.id = data.id;
        this.mondayStart = data.mondayStart && moment.utc(data.mondayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.mondayEnd = data.mondayEnd  && moment.utc(data.mondayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.mondayClosed = data.mondayClosed;
        this.mondayAllDay = data.mondayAllDay;
        this.tuesdayStart = data.tuesdayStart && moment.utc(data.tuesdayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.tuesdayEnd = data.tuesdayEnd && moment.utc(data.tuesdayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.tuesdayClosed = data.tuesdayClosed;
        this.tuesdayAllDay = data.tuesdayAllDay;
        this.wednesdayStart = data.wednesdayStart && moment.utc(data.wednesdayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.wednesdayEnd = data.wednesdayEnd && moment.utc(data.wednesdayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.wednesdayClosed = data.wednesdayClosed;
        this.wednesdayAllDay = data.wednesdayAllDay;
        this.thursdayStart = data.thursdayStart && moment.utc(data.thursdayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.thursdayEnd = data.thursdayEnd && moment.utc(data.thursdayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.thursdayClosed = data.thursdayClosed;
        this.thursdayAllDay = data.thursdayAllDay;
        this.fridayStart = data.fridayStart && moment.utc(data.fridayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.fridayEnd = data.fridayEnd && moment.utc(data.fridayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.fridayClosed = data.fridayClosed;
        this.fridayAllDay = data.fridayAllDay;
        this.saturdayStart = data.saturdayStart && moment.utc(data.saturdayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.saturdayEnd = data.saturdayEnd && moment.utc(data.saturdayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.saturdayClosed = data.saturdayClosed;
        this.saturdayAllDay = data.saturdayAllDay;
        this.sundayStart = data.sundayStart && moment.utc(data.sundayStart, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.sundayEnd = data.sundayEnd && moment.utc(data.sundayEnd, TIME_API_FORMAT).tz(Timezone).format(TIME_API_FORMAT);
        this.sundayClosed = data.sundayClosed;
        this.sundayAllDay = data.sundayAllDay;
    }

    isClosed = (date: Moment): boolean => {
        const dateTime = date.format('HH:mm');
        const dateWeekDay = date.isoWeekday();

        const currentOpenTimeKeys = weekDayToOpenTime[dateWeekDay-1];
        const currentOpenTimeFrom = this[currentOpenTimeKeys[0]];
        const currentOpenTimeTo = this[currentOpenTimeKeys[1]];
        const currentOpenTimeClosed = this[currentOpenTimeKeys[2]];
        const currentOpenTimeAllDay = this[currentOpenTimeKeys[3]];

        if (currentOpenTimeClosed) {
            return true;
        } else if (currentOpenTimeAllDay) {
            return false;
        } else if (currentOpenTimeFrom && currentOpenTimeTo) {
            return (
                moment(dateTime, 'HH:mm').isBefore(moment(currentOpenTimeFrom, 'HH:mm'))
                || moment(dateTime, 'HH:mm').isAfter(moment(currentOpenTimeTo, 'HH:mm'))
            );
        }

        return true;
    };
}
