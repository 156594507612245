import { Endpoints } from 'types/endpoints';
import { ActionResponse } from 'types/redux/ActionResponse';

import { Types } from 'models/UserAgree';

import { withVariables } from 'utils/string';
import { reduxRequest as request } from 'services/Api';
import { createSession, destroySession } from 'services/Auth';

import * as types from './types';

export interface SetAuthTokenParams {
    authToken: string;
}

export const setAuthToken = (params?: SetAuthTokenParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch({
        type: types.SET_AUTH_TOKEN,
        payload: params,
    });
};

export interface LoginParams {
    password: string;
    login: string;
}

export const login = (params?: LoginParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.LOGIN, params),
            method: 'POST',
            path: Endpoints.PublicUsersToken,
            params,
            requestParams: params,
        })
    )
        .then(response => {
            createSession(response.payload.data);
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};


export interface LoginByPhoneParams {
    phone: string;
    code: string;
}

export const loginByPhone = (params?: LoginByPhoneParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.LOGIN, params),
            method: 'POST',
            path: Endpoints.PublicUsersCodesToken,
            params,
            requestParams: params,
        })
    )
        .then(response => {
            createSession(response.payload.data);
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface AssignCodeParams {
    phone: string;
}

export const assignCode = (params?: AssignCodeParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: withVariables(types.ASSIGN_CODE, params),
            method: 'POST',
            path: Endpoints.PublicUsersAssignCode,
            params,
            requestParams: params,
        })
    ).catch(error => {
        console.error(error);
        throw error;
    });
};

export interface LogoutParams {
}

export const logout = (params?: LogoutParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.LOGOUT,
            method: 'DELETE',
            path: withVariables(Endpoints.PublicUsersToken, params),
            params,
        })
    )
        .then(response => {
            destroySession();
            return response;
        })
        .catch(error => {
            console.error(error);

            destroySession();
            throw error;
        });
};


export interface ProfileGetParams {
}

export const profileGet = (params?: ProfileGetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PROFILE_GET,
            method: 'GET',
            path: withVariables(Endpoints.AuthMe, params),
            params,
            actionsOnCode: {
                401: () => dispatch(logout()),
            },
        })
    )
        .catch(error => {
            destroySession();
            console.error(error);
            throw error;
        });
};

export interface ProfileUpdateParams {
    firstName: string;
    lastName: string;
    email: string;
    phonePrefix?: string;
    phone: string;
    gender?: string;
    dateBirth: string;
    preferredLocationId?: string;
}

export const profileUpdate = (params?: ProfileUpdateParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PROFILE_UPDATE,
            method: 'PUT',
            path: withVariables(Endpoints.AuthMe, params),
            params,
            requestParams: params,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface ProfileGenderUpdateParams {
    gender: string;
}

export const profileGenderUpdate = (params?: ProfileGenderUpdateParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PROFILE_GENDER_UPDATE,
            method: 'PUT',
            path: withVariables(Endpoints.AuthMeGender, params),
            params,
            requestParams: params,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface InvoiceParams {
    companyName: string;
    companyVatId: string;
    companyCity: string;
    companyPostCode: string;
    companyAddress: string;
    country: string;
}

export const invoice = (params?: InvoiceParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.INVOICE,
            method: 'PUT',
            path: withVariables(Endpoints.AuthMeInvoice, params),
            params,
            requestParams: params,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};


export interface PasswordResetParams {
    email: string;
}

export const passwordReset = (params?: PasswordResetParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PASSWORD_RESET,
            method: 'DELETE',
            path: Endpoints.PublicUsersPassword,
            params,
            requestParams: params,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface PublicPasswordChangeParams {
    token: string;
    password: string;
    passwordConfirm: string;
}

export const publicPasswordChange = (params: PasswordChangeParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PUBLIC_PASSWORD_CHANGE,
            method: 'POST',
            path: Endpoints.PublicUsersPassword,
            params,
            requestParams: params,
        })
    )
        .then(response => {
            createSession(response.payload.data);
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface PasswordChangeParams {
    password: string;
    passwordConfirm: string;
}

export const passwordChange = (params: PasswordChangeParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PASSWORD_CHANGE,
            method: 'POST',
            path: Endpoints.AuthMePassword,
            params,
            requestParams: params,
        })
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface RegisterParams {
    email: string;
    phonePrefix?: number;
    phone: number;
    firstName: string;
    lastName: string;
    gender?: string;
    userAgrees?: {
        type: Types;
        value: boolean;
        content: string | React.ReactElement;
    }[];
}

export const register = (params: RegisterParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.REGISTER,
            method: 'POST',
            path: Endpoints.PublicUsers,
            params,
            requestParams: params,
        })
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface ProfileDeleteParams {
}

export const profileDelete = (params?: ProfileDeleteParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.PROFILE_DELETE,
            method: 'DELETE',
            path: withVariables(Endpoints.AuthMe, params),
            params,
            requestParams: params,
        })
    )
        .then(response => {
            destroySession();
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface SetUserAgreesParams {
    userAgrees: {
        type: Types;
        value: boolean;
        content: string | React.ReactElement;
    }[];
}

export const setUserAgrees = (params?: SetUserAgreesParams) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.SET_USER_AGREES,
            method: 'PUT',
            path: withVariables(Endpoints.AuthMeUserAgrees, params),
            params,
            requestParams: params,
        })
    )
        .catch(error => {
            console.error(error);
            throw error;
        });
};


