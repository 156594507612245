import getConfig from 'next/config';

import CartItem from 'models/CartItem';
import Model from 'models/Model';

import { changeFromCent } from 'utils/math';

export default class GtmVoucherCartItem implements Model {
    id: string;
    name: string;
    quantity: number;
    price: number;
    category: string;
    itemType: string;
    gender: string;
    currency: string;

    constructor(data: CartItem) {
        this.id = data?.item?.id ? data.item.id : data.id;
        this.name = data?.item?.id ? data.item.name : `custom voucher ${changeFromCent(data.data.voucherValue)}`;
        this.quantity = 1;
        this.price = data?.item?.id ? changeFromCent(data?.item?.pricing.price) : changeFromCent(data.data.voucherValue);
        this.category = data.itemType;
        this.itemType = data.itemType;
        this.gender = '';
        this.currency = data?.item?.pricing?.currency.code ? data.item.pricing.currency.code : this.getCurrency();
    }

    getCurrency = () => {
        const { publicRuntimeConfig } = getConfig();

        if (publicRuntimeConfig.APP_LOCALE === 'pl') return 'PLN';
        if (publicRuntimeConfig.APP_LOCALE === 'en') return 'GBP';
    };
}
