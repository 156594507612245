import CartItem from 'models/CartItem';
import Model from 'models/Model';

import { changeFromCent } from 'utils/math';

export default class GtmVoucherCheckout implements Model {
    id: string;
    name: string;
    quantity: number;
    price: number;
    category: string;
    itemType: string;
    gender: string;
    currency: string;

    constructor(data: CartItem) {
        this.id = data?.item?.id ? data.item.id : data.id;
        this.name = data?.item?.id ? data.item.name : `custom voucher ${changeFromCent(data.data.voucherValue)}`;
        this.quantity = 1;
        this.price = data?.item?.id ? data?.item?.pricing.price.toFixed(2) : changeFromCent(data.data.voucherValue).toFixed(2);
        this.category = data.itemType;
        this.itemType = data.itemType;
        this.gender = '';
        this.currency = data?.item?.pricing?.currency.code && data.item.pricing.currency.code || null;
    }
}
