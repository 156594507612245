import ApiBundleStage from 'types/api/BundleStage';
import { Locales } from 'types/locale';

import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

export enum Stages {
    Before = 'before',
    Ongoing = 'ongoing',
    After = 'after',
}

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
    [Locales.En]: require('locales/models/en.json'),
});

export default class BundleStage implements Model {
    id: string;
    stage: Stages;
    description: string;
    order: number;
    title: string;

    constructor(data: ApiBundleStage) {
        this.id = data.id;
        this.stage = data.stage;
        this.description = data.description;
        this.order = data.order;
        this.title = this.getStageTitle(this.stage);
    }

    getStageTitle = (stage: Stages): string => {
        switch (stage) {
            case Stages.Before:
                return translationsModels?.productStage?.stages?.before;
            case Stages.Ongoing:
                return translationsModels?.productStage?.stages?.ongoing;
            case Stages.After:
                return translationsModels?.productStage?.stages?.after;
        }
    };
}
