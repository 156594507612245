import Model from 'models/Model';

export default class ModelOpenTime implements Model {
    id: string;
    mondayStart: string;
    mondayEnd: string;
    mondayClosed: boolean;
    mondayAllDay: boolean;
    tuesdayStart: string;
    tuesdayEnd: string;
    tuesdayClosed: boolean;
    tuesdayAllDay: boolean;
    wednesdayStart: string;
    wednesdayEnd: string;
    wednesdayClosed: boolean;
    wednesdayAllDay: boolean;
    thursdayStart: string;
    thursdayEnd: string;
    thursdayClosed: boolean;
    thursdayAllDay: boolean;
    fridayStart: string;
    fridayEnd: string;
    fridayClosed: boolean;
    fridayAllDay: boolean;
    saturdayStart: string;
    saturdayEnd: string;
    saturdayClosed: boolean;
    saturdayAllDay: boolean;
    sundayStart: string;
    sundayEnd: string;
    sundayClosed: boolean;
    sundayAllDay: boolean;
}


export const weekDayToOpenTime = [
    ['mondayStart', 'mondayEnd', 'mondayClosed', 'mondayAllDay'],
    ['tuesdayStart', 'tuesdayEnd', 'tuesdayClosed', 'tuesdayAllDay'],
    ['wednesdayStart', 'wednesdayEnd', 'wednesdayClosed', 'wednesdayAllDay'],
    ['thursdayStart', 'thursdayEnd', 'thursdayClosed', 'thursdayAllDay'],
    ['fridayStart', 'fridayEnd', 'fridayClosed', 'fridayAllDay'],
    ['saturdayStart', 'saturdayEnd', 'saturdayClosed', 'saturdayAllDay'],
    ['sundayStart', 'sundayEnd', 'sundayClosed', 'sundayAllDay'],
];