import moment, { Moment } from 'moment-timezone';

import ApiBundlePromo from 'types/api/BundlePromo';

import Bundle from 'models/Bundle';
import BundlePromoPricing from 'models/BundlePromoPricing';
import { Timezone } from 'models/Market';
import Model from 'models/Model';

import { getFormattedDate } from 'utils/date';

export default class BundlePromo implements Model {
    id: string;
    name: string;
    slug: string;
    startAt: Moment;
    startAtDisplay: string;
    endAt: Moment;
    endAtDisplay: string;
    bundle?: Bundle;
    pricing: BundlePromoPricing;

    constructor(data: ApiBundlePromo) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.startAt = data.startAt && moment.utc(data.startAt).tz(Timezone);
        this.startAtDisplay = this.startAt && getFormattedDate(this.startAt);
        this.endAt = data.endAt && moment.utc(data.endAt).tz(Timezone);
        this.endAtDisplay = this.endAt && getFormattedDate(this.endAt, 'DD.MM.YYYY');
        this.bundle = data.bundle && new Bundle(data.bundle) || null;
        this.pricing = data.pricing && new BundlePromoPricing(data.pricing);
    }
}
