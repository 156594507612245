export enum CookieKeys {
    AuthToken = 's9EbqQjzVs9xAJYM',
    NextLocale = 'Cpq54n7VM3sA7hTC',
    Visitor = 'mTLDmyPW2KCMP9vG',
    VisitorId = 'xzn4KCURKjDbEsZW',
    AttributionFirst = 'PL28PMndtVTkzQyk',
    AttributionLast = '8YNz6mL8kJWAmKBr',
    Gender = 'gender',
    Category = 'category',
    ArticleAccess = 'articleAccess',
}