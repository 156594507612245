import ApiBundlePricing from 'types/api/BundlePricing';

import Currency from 'models/Currency';
import Model from 'models/Model';

import { changeFromCent, displayPrice } from 'utils/math';

export default class BundlePricing implements Model {
    id: string;
    currency: Currency;
    price: number;
    priceDisplay: string;
    lowestPriceValue: number;
    lowestPriceValueisplay: string;

    constructor(data: ApiBundlePricing) {
        const newCurrency = data.currency && new Currency(data.currency);
        const price = changeFromCent(data.price);

        this.id = data.id;
        this.currency = newCurrency;
        this.price = price || 0;
        this.priceDisplay = displayPrice(price, newCurrency);
        this.lowestPriceValue = changeFromCent(data.lowestPriceValue) || 0;
        this.lowestPriceValueisplay = displayPrice(this.lowestPriceValue, newCurrency);
    }
}
