export default `
    .styled-h-scrollbar {
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffefdf;
            box-shadow: inset 0 0 5px 0px rgb(0 0 0 / 20%)
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 9px;
            box-shadow: inset 0 0 5px 0px rgb(0 0 0 / 20%);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #ba9449;
        }
    }

    .regulations {
        .layout-forms-input-wrapper {
            .internal-wrapper {
                .input-body {
                    .label {
                        span {
                            .regulations {
                                color: #BA9449;
                                text-decoration: none;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
