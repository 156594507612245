import moment, { Moment } from 'moment-timezone';

import ApiProductPromo from 'types/api/ProductPromo';
import { Option } from 'types/options';

import { Timezone } from 'models/Market';
import Model from 'models/Model';
import Product from 'models/Product';
import ProductPricingMatrix from 'models/ProductPricingMatrix';
import ProductPromoPricing from 'models/ProductPromoPricing';

import { getFormattedDate } from 'utils/date';
import { displayPrice } from 'utils/math';

export default class ProductPromo implements Model {
    id: string;
    name: string;
    slug: string;
    startAt: Moment;
    startAtDisplay: string;
    endAt: Moment;
    endAtDisplay: string;
    product?: Product;
    pricing: ProductPromoPricing;
    pricingMatrixes: ProductPricingMatrix[];
    pricingMatrixOptions: Option<number>[];

    constructor(data: ApiProductPromo) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.startAt = data.startAt && moment.utc(data.startAt).tz(Timezone);
        this.startAtDisplay = this.startAt && getFormattedDate(this.startAt);
        this.endAt = data.endAt && moment.utc(data.endAt).tz(Timezone);
        this.endAtDisplay = this.endAt && getFormattedDate(this.endAt, 'DD.MM.YYYY');
        this.product = data.product && new Product(data.product) || null;
        this.pricing = data.pricing && new ProductPromoPricing(data.pricing);
        this.pricingMatrixes = Array.isArray(data.pricingMatrix)
            ? data.pricingMatrix
                .map(pricingMatrix => new ProductPricingMatrix(pricingMatrix))
                .sort((elemA, elemB) => elemA.quantityFrom - elemB.quantityFrom)
            : [];
        this.pricingMatrixOptions = this.getPricingMatrixOptions(this.pricingMatrixes);
    }

    getPricingMatrixOptions = (pricingMatrixes: ProductPricingMatrix[]): Option<number>[] => {
        if(!Array.isArray(pricingMatrixes)) return [];

        return pricingMatrixes.map(pricingMatrix => {
            const price = pricingMatrix?.price * pricingMatrix?.quantityFrom;
            const priceDisplay = displayPrice(price, pricingMatrix?.currency);

            return ({
                label: `${pricingMatrix?.quantityFrom} za ${priceDisplay}`,
                value: pricingMatrix?.quantityFrom,
            });
        });
    };

    getMatrixPrice = (matrixes: ProductPricingMatrix[], quantity: number): ProductPricingMatrix | null => {
        return matrixes.find(matrix => {
            if(quantity < matrix.quantityFrom) {
                return false;
            }

            if(quantity > matrix.quantityTo) {
                return false;
            }

            return true;
        });
    };
}
