import { createContext } from 'react';

import Article from 'models/Article';
import Location from 'models/Location';
import Market from 'models/Market';

export interface LocaleContext {
    market: Market;
    locations: Location[];
    articles: Article[];
}

const initialState: LocaleContext = {
    market: null,
    locations: [],
    articles: [],
};

const MarketContext = createContext(initialState);
export default MarketContext;