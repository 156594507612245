import ApiUserAgree from 'types/api/UserAgree';
import { Locales } from 'types/locale';
import { Option } from 'types/options';

import Model from 'models/Model';

import { select as selectLocale } from 'utils/locale';

export enum Types {
    SelectAll = 'selectAll',
    Regulations = 'regulations',
    MarketingEmail = 'marketingEmail',
    MarketingPhone = 'marketingPhone',
    Analytics = 'analytics',
    Professional = 'professional',
}

const translationsModels = selectLocale({
    [Locales.Pl]: require('locales/models/pl.json'),
    [Locales.En]: require('locales/models/en.json'),
});

export default class UserAgree implements Model {
    id: string;
    type: Types;
    typeOption: Option<Types>;
    value: boolean;
    content: string;

    constructor(data: ApiUserAgree) {
        this.id = data.id;
        this.type = data.type;
        this.value = data.value;
        this.typeOption = this.getTypeOption(this.type);
    }

    getTypeOption = (value: Types): Option<Types> => {
        return typeOptions.find(option => option.value === value);
    };
}

export const typeOptions = [{
    value: Types.SelectAll,
    required: false,
    label: translationsModels?.userAgree?.typeOptions?.selectAll,
}, {
    value: Types.Regulations,
    required: true,
    label: translationsModels?.userAgree?.typeOptions?.regulations,
}, {
    value: Types.MarketingEmail,
    required: false,
    label: translationsModels?.userAgree?.typeOptions?.marketingEmail,
}, {
    value: Types.MarketingPhone,
    required: false,
    label: translationsModels?.userAgree?.typeOptions?.marketingPhone,
}];
