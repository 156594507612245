export default {
    getItem: (name: string): any => {
        try {
            return JSON.parse(localStorage.getItem(name));
        } catch (e) {
            console.error(e);
            return null;
        }
    },
    setItem: (name: string, data: object): any => {
        try {
            return localStorage.setItem(name, JSON.stringify(data));
        } catch (e) {
            console.error(e);
            return null;
        }
    },
    removeItem: (name: string): boolean => {
        try {
            localStorage.removeItem(name);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    },
};
