import ApiArticleLocale from 'types/api/ArticleLocale';

import ArticleCategory from 'models/ArticleCategory';
import FileUpload from 'models/FileUpload';
import Locale from 'models/Locale';
import LocalePromoBar from 'models/LocalePromoBar';
import Model from 'models/Model';

const PLACEHOLDER_IMAGE = {
    id: `fake-${Date.now()}`,
    imageUrl: '/images/placeholders/placeholder_article.jpg',
    alt: null,
    title: null,
    mimeType: null,
    fileName: null,
    originalName: null,
    putUrl: null,
    getUrl: null,
    storagePath: null,
};

export default class ArticleLocale implements Model {
    id: string;
    locale: Locale;
    title: string;
    slug: string;
    lead: string;
    content: string;
    image?: FileUpload;
    imageMobile?: FileUpload;
    metaTitle?: string;
    metaDescription?: string;
    articlePromoContent?: LocalePromoBar;

    constructor(data: ApiArticleLocale, category?: ArticleCategory) {
        this.id = data.id;
        this.locale = data.locale && new Locale(data.locale) || null;
        this.title = data.title;
        this.slug = data.slug;
        this.lead = data.lead;
        this.content = data.content;
        this.image = data.image && new FileUpload(data.image) || null;
        this.image = this.getCoverImage(category).desktop;
        this.imageMobile = data.image && new FileUpload(data.image) || null;
        this.imageMobile = this.getCoverImage(category).mobile;
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
        this.articlePromoContent = data.promoBarVisible && new LocalePromoBar(data) || null;
    }

    getCoverImage = (category?: ArticleCategory): { desktop: FileUpload, mobile: FileUpload } => {
        let coverImage = this?.image;
        let coverImageMobile = this.imageMobile;

        if (!coverImage && category) coverImage = category?.coverImage;
        if (!coverImageMobile && category)  coverImageMobile = category?.coverImageMobile;

        if (!coverImage) coverImage = new FileUpload(PLACEHOLDER_IMAGE);
        if (!coverImageMobile) coverImageMobile = new FileUpload(PLACEHOLDER_IMAGE);

        return {
            desktop: coverImage,
            mobile: coverImageMobile,
        };
    };
}
