import ApiVoucherPricing from 'types/api/VoucherPricing';

import Currency from 'models/Currency';
import Model from 'models/Model';

import { changeFromCent, displayPrice } from 'utils/math';

export default class VoucherPricing implements Model {
    id: string;
    currency: Currency;
    price: number;
    priceDisplay: string;
    value: number;

    constructor(data: ApiVoucherPricing) {
        const newCurrency = data.currency && new Currency(data.currency);

        this.id = data.id;
        this.currency = newCurrency;
        this.price = data.price;
        this.priceDisplay = displayPrice(changeFromCent(data.price), newCurrency);
        this.value = data.value;
    }
}
