import ApiBundleComparison from 'types/api/BundleComparison';

import Model from 'models/Model';

export interface BeforeAfterImage {
	id: string;
	fileName: string;
	imageUrl: string;
}

export interface BeforeAfterListImages {
	imageBefore: BeforeAfterImage;
	imageAfter: BeforeAfterImage;
}

export default class BundleComparison implements Model {
    id: string;
    headline: string;
    subheadline: string;
    order?: number;
    imageBefore: string;
    imageAfter: string;
    imagesBeforeAfterList?: BeforeAfterListImages[];

    constructor(data: ApiBundleComparison) {
        this.id = data.id;
        this.headline = data.title || null;
        this.subheadline = data.content || null;
        this.order = data.order;
        this.imageBefore = data.imageBefore?.imageUrl || '';
        this.imageAfter = data.imageAfter?.imageUrl;
        this.imagesBeforeAfterList = data?.imagesBeforeAfterList || [];
    }
}
