import getConfig from 'next/config';
import axios, { AxiosRequestConfig } from 'axios';

import { ReduxRequestOptions, ReduxRequestPayload } from 'types/request';

import { FAIL_FLAG, INIT_FLAG, SUCCESS_FLAG } from 'consts/redux';
import { mapErrors } from 'utils/api';
import { parseToQueryString } from 'utils/querystring';
import Logger from 'services/Logger';

import { State as ReduxState } from 'store/state';

interface ExtendedProcess extends NodeJS.Process {
    redis?: any;
}

export const reduxRequest = (options: ReduxRequestOptions) => async (dispatch: Function) : Promise<ReduxRequestPayload> => {
    const { publicRuntimeConfig } = getConfig();

    // Transform ooptions
    const storeState = options.getState();
    options = transformOptions(options, storeState);

    // Redux loading side effect
    if(!options.ignoreState) {
        dispatch({
            state: INIT_FLAG,
            type: options.reduxType + '_' + INIT_FLAG,
            params: options.params,
        });
    }

    try {
        // Handle mocks
        if(publicRuntimeConfig.MOCKS && options.mock) {
            const response = await mockRequest(options);
            return options.ignoreState ? response : dispatch(response);
        }

        const response = await request(options);
        return options.ignoreState ? response : dispatch(response);
    } catch (error) {
        Logger.error(error);
        throw options.ignoreState
            ? error
            : dispatch(error);
    }
};

export async function request(options: ReduxRequestOptions): Promise<any> {
    const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

    options = transformOptions(options);
    const requestConfig = getRequestCofnig(options);
    const extendedProcess: ExtendedProcess = process;
    const cacheEnabled = Boolean(serverRuntimeConfig?.CACHE_ENABLED && options.cache?.enabled !== false && typeof window === 'undefined');
    const cacheKey = options.cache?.key || `${publicRuntimeConfig.MARKET_SLUG}-${requestConfig.url}`;
    const cacheTTL = options.cache?.ttl || 120;

    try {
        let response = null;

        // Read cache
        try {
            if(cacheEnabled && cacheKey && extendedProcess.redis) {
                response = await new Promise((resolve) => {
                    extendedProcess.redis.get(cacheKey, (error, result) => {
                        if(!error && result) {
                            return resolve({
                                status: 200,
                                data: JSON.parse(result),
                            });
                        }

                        return resolve(null);
                    });
                });
            }
        } catch (error) {
            Logger.error('redis get error', error);
        }

        // Make request
        if(!response) {
            response = await axios(requestConfig);

            // Set cache
            try {
                if(cacheEnabled && cacheKey && extendedProcess.redis) {
                    extendedProcess.redis.setex(cacheKey, cacheTTL, JSON.stringify(response?.data));
                }
            } catch (error) {
                Logger.error('cache set error', error);
            }
        }

        // Handle response
        if(options.actionsOnCode && options.actionsOnCode[response.status]) {
            options.actionsOnCode[response.status](response);
        }

        return {
            state: SUCCESS_FLAG,
            type: options.reduxType + '_' + SUCCESS_FLAG,
            payload: response,
            params: options.params,
        };
    } catch (error) {
        if(options.actionsOnCode && options.actionsOnCode[error?.response?.status]) {
            options.actionsOnCode[error?.response?.status](error?.response);
        }

        throw {
            state: FAIL_FLAG,
            type: options.reduxType + '_' + FAIL_FLAG,
            payload: mapErrors(error && error.response),
            params: options.params,
            error,
        };
    }
}

export function mockRequest(options: ReduxRequestOptions): Promise<any> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                state: SUCCESS_FLAG,
                type: options.reduxType + '_' + SUCCESS_FLAG,
                payload: options.mock(options.params),
                params: options.params,
            });
        }, 300);
    });
}

function getRequestCofnig(options: any): AxiosRequestConfig {
    const { publicRuntimeConfig } = getConfig();

    return {
        method: options.method.toUpperCase(),
        url: options.url,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Content-Language': publicRuntimeConfig.APP_LOCALE,
            ...options.headers,
        },
        data: options.data,
        timeout: options.timeout || 30000,
        responseType: 'json',
        adapter: options.adapter || undefined,
        validateStatus: status => {
            return status >= 200 && status < 300;
        },
        paramsSerializer: params => {
            return JSON.stringify(params);
        },
    };
}

function transformOptions(options: ReduxRequestOptions, storeState?: ReduxState) {
    const { publicRuntimeConfig } = getConfig();

    if(!storeState && typeof window !== 'undefined') {
        storeState = (window as any).__NEXT_REDUX_WRAPPER_STORE__?.getState();
    }

    // Attach headers
    options.headers = {
        ...options.headers || {},
        'market-slug': publicRuntimeConfig.MARKET_SLUG || undefined,
    };

    if(storeState?.visitor?.data?.uniqueId) {
        options.headers = {
            ...options.headers || {},
            'visitor-unique-id': storeState?.visitor?.data?.uniqueId,
        };
    }

    if(storeState?.user?.authToken) {
        options.headers = {
            ...options.headers || {},
            'authorization': storeState.user?.authToken
                ? 'Bearer ' + storeState.user.authToken
                : undefined,
        };
    }

    // Ensure that requestParams is object
    if (typeof options.requestParams !== 'object' || !options.requestParams) {
        options.requestParams = {};
    }

    // Support for PUT request methods
    if (options.method.toUpperCase() === 'PUT' && !options.forceMethod) {
        options.method = 'POST';
        options.requestParams._method = 'PUT';
    }

    // Support for DELETE request methods
    if (options.method.toUpperCase() === 'DELETE' && !options.forceMethod) {
        options.method = 'POST';
        options.requestParams._method = 'DELETE';
    }

    if (options.method.toUpperCase() === 'GET') {
        options.path = parseToQueryString(options.path, options.requestParams);
    }

    options.data = options.requestParams;
    if (options.asFormData) {
        const formData = new FormData();
        Object.keys(options.data).forEach(key => {
            formData.append(key, options.data[key]);
        });
        options.data = formData;
    }

    // Get api path
    options.url = publicRuntimeConfig.API_URL + options.path;
    if (options.apiUrl) {
        options.url = options.apiUrl + options.path;
    }

    return options;
}
