import ApiProductLists from 'types/api/ProductLists';

import Model from 'models/Model';

export default class ProductLists implements Model {
    id: string;
    title: string;
    content: string;
    order: number;

    constructor(data: ApiProductLists) {
        this.id = data.id;
        this.title = data.title || null;
        this.content = data.content || null;
        this.order = data.order;
    }
}
