import ApiDiscountCode from 'types/api/DiscountCode';

import Cart from 'models/Cart';
import Model from 'models/Model';
import ProductCategory from 'models/ProductCategory';

import { changeFromCent, displayPrice } from 'utils/math';

export enum Types {
    Percentage = 'percentage',
    Amount = 'amount',
}

export default class DiscountCode implements Model {
    id: string;
    discountType: Types;
    discountValue: number;
    discountValueDisplay: string;
    code: string;
    cart?: Cart;
    productCategory: ProductCategory;

    constructor(data: ApiDiscountCode) {
        this.id = data.id;
        this.discountType = data.discountType;
        this.discountValue = this.discountType === Types.Amount
            ? changeFromCent(data.discountValue)
            : data.discountValue;
        this.discountValueDisplay = this.getDiscountCodeValueDisplay();
        this.code = data.code;
        this.productCategory = data.productCategory && new ProductCategory(data.productCategory);
    }

    getDiscountCodeValueDisplay = (): string => {
        if(this.discountType === Types.Amount) {
            return displayPrice(this.discountValue) + ' zł';
        }

        if(this.discountType === Types.Percentage) {
            return this.discountValue + '%';
        }

        return null;
    };
}
