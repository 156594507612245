import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AppContext } from 'next/app';
import { css, Global } from '@emotion/react';
import { Provider } from '@rollbar/react';
import { wrapper } from 'store';

import Rollbar from 'services/Rollbar';

import stylesBreakpoints from 'theme/styles/breakpoints';
import stylesCookieconsent from 'theme/styles/cookieconsent';
import stylesGlobal from 'theme/styles/global';
import stylesReset from 'theme/styles/reset';
import stylesScrollbar from 'theme/styles/scrollbar';
import stylesToastify from 'theme/styles/toastify';

import WrapperApp from 'components/wrappers/App';

function WrapperRoot({ Component, pageProps }) {
    return (
        <>
            <Provider instance={Rollbar}>
                <Global styles={css`${stylesReset}`} />
                <Global styles={css`${stylesGlobal}`} />
                <Global styles={css`${stylesBreakpoints}`} />
                <Global styles={css`${stylesToastify}`} />
                <Global styles={css`${stylesScrollbar}`} />
                <Global styles={css`${stylesCookieconsent}`} />
                <ToastContainer
                    newestOnTop={true}
                    limit={2}
                />
                <WrapperApp {...pageProps}>
                    <Component {...pageProps} />
                </WrapperApp>
            </Provider>
        </>
    );
}

WrapperRoot.getInitialProps = async (appContext: AppContext) => {
    const appProps = await WrapperApp.getInitialProps(appContext);

    let pageProps = {};
    if (appContext.Component.getInitialProps) {
        pageProps = await appContext.Component.getInitialProps(appContext.ctx);
    }

    return {
        pageProps: {
            ...appProps,
            ...pageProps,
        },
    };
};

export default wrapper.withRedux(WrapperRoot);
