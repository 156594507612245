import ApiArticleCategory from 'types/api/ArticleCategory';

import ArticleCategoryLocale from 'models/ArticleCategoryLocale';
import FileUpload from 'models/FileUpload';
import Model from 'models/Model';

export default class ArticleCategory implements Model {
    id: string;
    coverImage?: FileUpload;
    coverImageMobile?: FileUpload;
    locale: ArticleCategoryLocale;

    constructor(data: ApiArticleCategory) {
        this.id = data.id;
        this.coverImage = data.coverImage && new FileUpload(data.coverImage) || null;
        this.coverImageMobile = data.coverImageMobile && new FileUpload(data.coverImageMobile) || null;
        this.locale = data.locale && new ArticleCategoryLocale(data.locale) || null;
    }
}
