export default `
    html, body, * {
        font-family: 'Lato', Arial, sans-serif;
    }

    html, body {
        font-size: 16px;
    }

    html {
        overflow-x: hidden;
    }

    h1, h2, h3, h4, h5, h6, p, span {
        line-height: 120%;
    }

    h2, h3 {
        font-size: 120%;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Lato', Arial, sans-serif;
    }

    h4 {
        font-weight: 400;
    }

    h4 {
        font-weight: 700;
    }
`;
