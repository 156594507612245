import ApiProductPromoPricing from 'types/api/ProductPromoPricing';

import Currency from 'models/Currency';
import Model from 'models/Model';

import { changeFromCent, displayPrice } from 'utils/math';

export default class ProductPromoPricing implements Model {
    id: string;
    price: number;
    priceDisplay: string;
    currency: Currency;

    constructor(data: ApiProductPromoPricing) {
        this.id = data.id;
        this.currency = data.currency && new Currency(data.currency) || null;
        this.price = data.price && changeFromCent(data.price) || 0;
        this.priceDisplay = displayPrice(this.price, this.currency);
    }
}
