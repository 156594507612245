import cart, { initialState as cartInitialState, State as CartState } from 'store/modules/carts/reducer';
import markets, { initialState as marketsInitialState, State as MarketsState } from 'store/modules/markets/reducer';
import user, { initialState as userInitialState, State as UserState } from 'store/modules/user/reducer';
import visitor, { initialState as visitorInitialState, State as VisitorState } from 'store/modules/visitors/reducer';

export interface State {
    markets: MarketsState;
    user: UserState;
    visitor: VisitorState;
    cart: CartState;
}

export const initialState = {
    markets: marketsInitialState,
    user: userInitialState,
    visitor: visitorInitialState,
    cart: cartInitialState,
};

export default {
    markets,
    user,
    visitor,
    cart,
};