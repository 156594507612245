import ApiLocation from 'types/api/Location';
import { MapLocation } from 'types/gmaps';

import City from 'models/City';
import FileUpload, { getPlaceholder } from 'models/FileUpload';
import LocationLocale from 'models/LocationLocale';
import LocationOpenTime from 'models/LocationOpenTime';
import Model from 'models/Model';
import ProductCategory from 'models/ProductCategory';

import { Option } from '../types/options';

export default class Location implements Model {
    id: string;
    name: string;
    adidCode: string;
    country: string;
    region: string;
    city: City;
    postalCode: string;
    address: string;
    displayAddress: string;
    displayName: string;
    displayAddressReverse: string;
    displayAddressPlain: string;
    location?: MapLocation;
    phone: string;
    email: string;
    images: FileUpload[];
    locale: LocationLocale;
    openTime: LocationOpenTime;
    productCategories: ProductCategory[];
    isVirtual?: boolean;

    constructor(data: ApiLocation) {
        this.id = data.id;
        this.name = data.name;
        this.adidCode = data.adidCode;
        this.country = data.country;
        this.city = data.city && new City(data.city) || null;
        this.address = data.address;
        this.postalCode = data.postalCode;
        this.displayAddress = `${this.address}, ${this.city?.name}`;
        this.displayAddressReverse = `${this.city?.name}, (${this.address})`;
        this.displayAddressPlain = `${this.address}, ${this.city?.name} ${this.postalCode}`;
        this.email = data.email;
        this.phone = data.phone;
        this.location = data.location;
        this.images = Array.isArray(data.images)
            ? data.images.map(image => new FileUpload(image))
            : [getPlaceholder()];
        this.locale = data.locale && new LocationLocale(data.locale) || null;
        this.openTime = data.openTime && new LocationOpenTime(data.openTime);
        this.displayName = `${this.locale?.name} (${this.displayAddress})`;
        this.productCategories = Array.isArray(data.productCategories)
            ? data.productCategories?.map(category => new ProductCategory(category))
            : [];
        this.isVirtual = Boolean(data?.isVirtual);
    }

    getOption = (): Option<Location> => {
        return {
            value: this,
            label: this.displayAddressReverse,
        };
    };
}
